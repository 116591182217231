<template>
  <div class="container">
    <div class="m-auto w-25">
      <router-link class="text-center" to="/login">&larr; Sign In</router-link>
    </div>
    <form id="form" v-stream:submit="submit$">
      <TextInput
        v-focus
        required
        v-model="pin"
        type="text"
        :validator="$v.pin"
        name="pin"
        label="Security Code"
        id="pin"
        placeholder="Security Code"
      />
      <button type="button" class="btn text-primary pin-link" v-stream:click="pinRequest$">
        Request new code?
      </button>
      <div class="d-flex flex-column align-items-center mt-2">
        <div class="error text-center mt-2 mb-0">
          <p id="error" v-if="error" class="text-danger">{{ error }}</p>
          <loader size="small" v-else-if="loading"></loader>
        </div>
        <button :disabled="loading" type="submit" class="mx-auto my-2 btn btn-primary">
          Submit
        </button>
      </div>
      <!-- <idle-view /> -->
    </form>
  </div>
</template>

<script>
import { catchError, filter, mergeMap, switchMap, tap, throttleTime } from "rxjs/operators";
import Users from "../../../services/users";
import { mapActions, mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { createLogItem, validatorMsgMapBase } from "../../../modules/helpers";
import auditLog from "../../../services/AuditLog";
import Loader from "@/components/common/Loader.vue";
import TextInput from "@/components/common/TextInput.vue";

export default {
  components: { Loader, TextInput },
  name: "Pin",
  props: {
    redirect: {
      default() {
        return "/";
      }
    }
  },
  data() {
    return {
      pin: "",
      loading: false,
      error: ""
    };
  },
  validations: {
    pin: {
      required
    }
  },
  domStreams: ["submit$", "pinRequest$"],
  subscriptions() {
    const submit$ = this.submit$.pipe(
      tap(({ event }) => event.preventDefault()),
      filter(() => {
        this.$v.$touch();
        return !this.$v.$invalid;
      })
    );
    const requestPin$ = this.pinRequest$.pipe(
      throttleTime(10000),
      switchMap(() => this.requestNewPin())
    );

    const verifyPin$ = submit$.pipe(
      mergeMap(async () => {
        const response = await Users.verifyMFAPin(this.pin);
        if (response.pinIsValid) {
          this.$store.dispatch("verifyPin");
          const { userMustChangePassword } = this.currentUser;
          if (userMustChangePassword) {
            return this.$router.push({
              name: "ExpiredPassword",
              query: {
                redirect: this.redirect
              }
            });
          }
          auditLog
            .insertLogMessage({
              ...createLogItem({}, 16),
              comments: "Submitted MFA pin correctly."
            })
            .catch();
          this.loadApp();
        } else {
          throw "Invalid pin please try again";
        }
        return;
      }),
      catchError(() => {
        this.loading = false;
        this.error = "Error validating pin, please check your input and try again.";
        return verifyPin$;
      })
    );

    return {
      verifyPin$,
      requestPin$
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab", "availableLabs"]),
    ...mapGetters(["mustSelectLab"]),
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        nonAlpha: "Must include at least one none alpha numeric character.",
        minLength: "Must be at least 8 characters.",
        sameAs: "Must match the password field."
      };
    }
  },
  methods: {
    ...mapActions(["authenticate"]),
    async loadApp() {
      this.loading = true;
      this.loadingMsg = "Gathering resources...";
      if (this.currentLab) {
        this.loadingMsg = "";
      } else if (this.availableLabs.length === 1) {
        this.$store.dispatch("setCurrentLab", this.availableLabs[0].id);
      }
      if (this.mustSelectLab) {
        return;
      }
      this.$router.push("/");
    },
    requestNewPin() {
      return Users.createPin().then(() => {
        window.notify(`New pin sent!`, undefined, 2000);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.pin-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
</style>
